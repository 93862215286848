import React, { useState, useRef } from 'react';
import Webcam from 'react-webcam';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { useFormikContext, useField } from 'formik';

function CameraCapture({...props}) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [capturedImage, setCapturedImage] = useState(null);
  const color = useSelector((state) => state.color);
  const webcamRef = useRef(null);
  const { setFieldValue } = useFormikContext();
  const [field] = useField('imageFile');
  

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const capturePhoto = () => {
    const imageSrc = webcamRef.current.getScreenshot({width: 640, height: 480});
    console.log(imageSrc);
    setCapturedImage(imageSrc);
    setFieldValue('imageFile', imageSrc);
    closeModal();
  };

  const customStyles = {
    content: {
      maxWidth: '414px',
      height: '70%',
      margin: '0 auto',
      borderRadius: '20px'
    }
  };

  return (
    <div className="form-group mb-3">
      <label className="input-label">Front View Photo</label>
      <div style={{width:'100%', alignItems:'center'}}>
        {capturedImage && (
            <img
              src={capturedImage}
              alt="Captured"
              style={{ 
                width: '100%', 
                height:'100%' , 
                border: '1px solid',
                color: color,
                margin: '1rem',
                marginLeft: '0',
                borderRadius: '10px',
                marginBottom: '10px' 
              }}
            />
          )}
      </div>
      <div style={{display:'flex', justifyContent:'center'}}>
        <input
          type="button"
          name='imageFile'
          accept="image/jpeg"
          className={`form-control-file text-input`}
          {...field}
          {...props}
          autoComplete="off"
          onClick={openModal}
          value="Open Camera"
          style={{ width: 'auto', border:'1px solid', color: color }}
        />
      </div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Camera Modal"
          style={customStyles}
        >
          <div className='cammodalcontainer'>
            <div className='modalclose'>
              <button onClick={closeModal} className='closemodal'>
              <FontAwesomeIcon style={{ color: color }} icon={faCircleXmark} />
              </button>
            </div>
            <div className='camcontainer'>
              {modalIsOpen ? (
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  style={{ width: '100%', borderRadius:'10px', border: '1px solid', borderColor: color, }}
                />
                
              ) : null}
            </div>
            <div className='capturecontainer'>
              {modalIsOpen ? (
                <button onClick={capturePhoto} className='capture-button' style={{backgroundColor: color}}>Capture</button>
              ) : null}
            </div>
          </div>
        </Modal>
    </div>
  );
}

export default CameraCapture;