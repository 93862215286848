import { useDispatch, useSelector } from "react-redux";
import formatCurrency from "../../../utils/formatCurrency";
import InputText from "../../form/InputText";
import InputImage from "../../form/InputImage";
import Select from "../../form/Select";
import CameraCapture from "../../form/CameraCapture";
import { Formik } from "formik";
import * as Yup from "yup";

import { GetTotalCost, RecapCart, calculateOrderDiscount } from "../../../helper/cartHelper";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { clearCart } from "../../../redux/actions/cartAction";

import { SocketContext } from "../../../context/socketContext";
import { Link } from "react-router-dom";

export default function FormCheckoutCommerce() {
  const color = useSelector((state) => state.color);
  const total_cost = GetTotalCost();
  const [isLoading, setIsLoading] = useState(false);
  const [isAccept, setIsAccept] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [tenantDiscount, setTenantDiscount] = useState(0);

  const socket = useContext(SocketContext);

  let recap = RecapCart();

  const dispatch = useDispatch();
  let formDataDefault = localStorage.getItem("form_data");
  let isDefaultExist = false;

  if (
    formDataDefault !== "" &&
    formDataDefault !== null &&
    formDataDefault !== undefined
  ) {
    formDataDefault = JSON.parse(formDataDefault);
    isDefaultExist = true;
  } else {
    isDefaultExist = false;
  }

  function prepareOrderDataBeforeSend(formData) {
    let recap_data = recap;
    for (let i = 0; i < recap.length; i++) {
      fetch(`${process.env.REACT_APP_API_URL}api/table/${recap[i].tenant_id}`)
        .then((response) => response.json())
        .then((data) => {
          let table = data.data;
          console.log("table_id", formData.order_table);
          const order_table = table.filter(
            (x) => x.table.index === parseInt(formData.order_table)
          );
          recap_data[i].order_table =
            order_table.length > 0
              ? order_table[0].table.id
              : table[0].table.id;
        });
    }

    return recap_data;
  }

  function createOrder(formData) {
    console.log(formData);
    setIsLoading(true);
    const user_id = localStorage.getItem("user_id");
    recap = prepareOrderDataBeforeSend(formData);
    handleImage(formData);

    setTimeout(async () => {
      const payload = {
        user_name: formData.user_name === "" ? "-" : formData.user_name,
        user_phonenumber: formData.user_phonenumber === "" ? "-" : formData.user_phonenumber,
        order_instruction: formData.order_instruction === "" ? "-" : formData.order_instruction,
        user_guest: formData.user_guest === "" ? "-" : formData.user_guest,
        user_id: user_id,
        address: formData.address === "" ? "-" : formData.address,
        latitude: formData.latitude,
        longitude: formData.longitude,
        image: formData.image === "" ? "-" : formData.image,
        sales_name: formData.sales_name === "" ? "-" : formData.sales_name,
        data: recap,
      };
      console.log(payload)
      const url = `${process.env.REACT_APP_API_URL}api/order/create`;

      try {
        const response = await axios.post(url, payload);
        const data = response.data.data.order_list;
        const tenants = response.data.data.tenant_list;
        for (let i = 0; i < data.length; i++) {
          await socket.emit("add order", {
            tenant_id: tenants[i],
            order_id: data[i],
          });
        }
        let localOrderData = localStorage.getItem("order_data");
        if (localOrderData === null || localOrderData === "") {
          localStorage.setItem("order_data", JSON.stringify(data));
        } else {
          localOrderData = JSON.parse(localOrderData);
          localOrderData = localOrderData.concat(data);
          localStorage.setItem("order_data", JSON.stringify(localOrderData));
        }
        localStorage.setItem("cart", JSON.stringify([]));
        localStorage.setItem("curr_table", formData.order_table);
        localStorage.setItem("form_data", JSON.stringify(formData));
        dispatch(clearCart([]));
        setInterval(function () {
          setIsLoading(false);
          window.location = "/Order?id=" + data[0];
        }, 500);
      } catch (error) {
        setIsLoading(false);
        alert(error.response.data.message);
        // console.log("ERROR", error.response.data.message);
      }
    }, 3000);
  }

  const [table, setTable] = useState([]);
  useEffect(() => {
    async function fetchTable(tenant_id) {
      const response = await axios
        .get(`${process.env.REACT_APP_API_URL}api/table/${tenant_id}`)

        .catch((err) => {
          console.log("Error: ", err);
        });
      const data = response.data.data;
      setTable(data);
      
      const discountTenant = await calculateOrderDiscount(recap[0], tenant_id);
      setTenantDiscount(discountTenant?.discount_amount ?? 0);
    }
    fetchTable(recap[0].tenant_id);
  }, []);

  const validate = Yup.object({
    user_name: Yup.string(),
    user_phonenumber: Yup.string().matches(
      /\+?(^-)|([ -]?\d+)+|\(\d+\)([ -]\d+)/,
      "Phone number is not valid."
    ),
  });

  const handleImage = async (formData) => {
    const url = `${process.env.REACT_APP_API_URL}api/images/order/location/`;

    try {
      const data = new FormData();
      data.append('location_image', formData.imageFile);
      const response = await axios.post(url, data,);
  
      if (response.status === 200) {
        formData.image = response.data.data.file;
        console.log(formData.image);
      } else {
        console.error('Gagal mengunggah gambar');
      }
    } catch (error) {
      console.error('Terjadi kesalahan dalam permintaan:', error);
    }
  };

  return (
    <section className="my-3">
      <div className="row" style={{ marginBottom: "200px" }}>
        <div className="col-12">
          {!isLoading ? (
            <Formik
              initialValues={{
                user_name: isDefaultExist ? formDataDefault.user_name : "",
                user_phonenumber: isDefaultExist ? formDataDefault.user_phonenumber : "",
                order_instruction: isDefaultExist ? formDataDefault.order_instruction : "",
                user_guest: isDefaultExist ? formDataDefault.user_guest : 1,
                order_table: isDefaultExist ? formDataDefault.order_table : "",
                address: isDefaultExist ? formDataDefault.address : "",
                sales_name: isDefaultExist ? formDataDefault.sales_name : "",
                image: isDefaultExist ? formDataDefault.image : "",
                imageFile: isDefaultExist ? formDataDefault.imageFile : "",
                latitude: localStorage.getItem('latitude') || "",
                longitude: localStorage.getItem('longitude') || "",
              }}
              validationSchema={validate}
              onSubmit={(e) => createOrder(e)}
            >
              {(formik) => (
                <form onSubmit={formik.handleSubmit}>
                  {/* {console.log(formik.values)} */}
                  <InputText
                    label="Name"
                    name="user_name"
                    type="text"
                    placeholder="Enter Your Name"
                  />
                  <InputText
                    label="Phone Number"
                    name="user_phonenumber"
                    type="text"
                    placeholder="Enter Your Phone Number"
                  />
                  <InputText
                    label="Address"
                    name="address"
                    type="text"
                    placeholder="Enter Your Address"
                  />
                  <InputText
                    label="Sales Name"
                    name="sales_name"
                    type="text"
                    placeholder="Enter Sales Name"
                  />
                  <CameraCapture />
                  {/*<InputImage
                    label="Front View Photo"
                    type="file"
                    name="imageFile"
                    accept="image/jpeg"
                    value={undefined}
                    onChange={(e) => formik.setFieldValue('imageFile', e.currentTarget.files[0])}
                    placeholder="Upload Place Image"
              />*/}
                  <div className="form-check mb-2 mr-sm-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={isAccept}
                      onChange={() => setIsAccept(!isAccept)}
                    />
                    <label className="form-check-label">
                      Saya menyetujui{" "}
                      <Link to={"/TermsCondition"}>"Syarat Ketentuan"</Link> dan
                      <Link to={"/PrivacyPolicy"}>
                        "Kebijakan Privasi"
                      </Link>{" "}
                      Oasis bagi pelanggan
                    </label>
                  </div>

                  <button
                    type="submit"
                    className="btn big-primary-button mt-5"
                    style={{ background: color }}
                    disabled={!isAccept}
                  >
                    Total Cost: {formatCurrency(total_cost - tenantDiscount)} | Confirm Order
                  </button>
                </form>
              )}
            </Formik>
          ) : (
            <div className="d-flex justify-content-center mt-5">
              <div className="spinner-grow text-success mr-3" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <div className="spinner-grow text-success mr-3" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <div className="spinner-grow text-success mr-3" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
 }