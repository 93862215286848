import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setColor } from "../../../redux/actions/utilsAction";
import HeaderBar from "../../navbar/HeaderBar";
import CartReceipts from "./CartReceipts";
import MenuLists from "./MenuLists";
import emptyCart from "../../../assets/images/emptyCart.png";

import FormCheckout from "./FormCheckout";
import FormCheckoutCommerce from "./FormCheckoutCommerce";

export default function CartPage() {
  const carts = useSelector((state) => state.cart);
  // Get foodcourt_id Data
  const [foodcourt_id, setFoodcourtId] = useState(null);
  const GetFoodcourtId = async () => {
    try {
      const tenant_id = carts[0].tenant_id;
      const response = await axios.get(`${process.env.REACT_APP_API_URL}api/tenant/user/${tenant_id}`);
      setFoodcourtId(response.data.data.foodcourt_id);
    } catch (error) {
      console.log("Cart Empty");
    }
  };
  // End of Get foodcourt_id Data

  // Get User Location
  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
  
          localStorage.setItem('latitude', latitude);
          localStorage.setItem('longitude', longitude);
          //console.log(latitude, longitude);
        },
        (error) => {
          console.error(error.message);
        }
      );
    } else {
      console.error('Geolocation tidak didukung oleh perangkat ini.');
    }
  };
  // End of Get User Location

  useEffect(() => {
    getLocation();
    GetFoodcourtId();
  }, []);

  return (
    <React.Fragment>
      <HeaderBar />

      <CartReceipts />
      
      {carts.length > 0 ? (
        <div className="container-body">
          <MenuLists />
          { foodcourt_id === `${process.env.REACT_APP_FOODCOURT_ID}` ? (
            <FormCheckoutCommerce />
          ) : (
            <FormCheckout />
          )}
        </div>
      ) : (
        <div className="container-body d-flex justify-content-center align-items-center">
          <div className="col-12 text-center">
            <img src={emptyCart} alt="" className="w-75 p-5" />
            <div className="mb-4">
              <b>Your Cart is Empty.</b>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
